import React, {Component} from 'react';
import FormChallenges from '../components/FormChallenges';
import Header from '../components/Header';
import cookie from 'react-cookies';

class ChallengesCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      event: {}
    };
  }

  componentDidMount() {
    let cookies = cookie.loadAll();

    if(!cookies.tokenId){
        window.location.replace("https://auth.goaltiming.be/login?client_id=1n014khs8r0ghlvuvg7vds0tbg&response_type=token&scope=email%20openid&redirect_uri=https%3A%2F%2Fadmin.goaltiming.be%2Fview&fbclid=IwAR02CuBWOwLypoSCXUZ_MPuHVGTK7KUyg2cmEOA1JDsrUZgM8KnM36PV6gA");
    }else{
        fetch("https://api.goaltiming.be/challenges/" + this.props.match.params.id + '/admin/',{
          headers: new Headers({
            'Authorization': cookies.tokenId
          }), 
        })
        .then(res => res.json())
        .then(
            (result) => {
              
            if(JSON.stringify(result.rankings) === JSON.stringify({})){
              result.rankings = [];
            }
            this.setState({
                isLoaded: true,
                event: result
            });
            },(error) => {
            this.setState({
                isLoaded: true,
                error
            });
            }
        )
    }
  }

  render() {
    const { error, isLoaded } = this.state;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader">Loading...</div>;
    } else {
      return (

        <div>
            {/* [ Header ] start */}
                <Header />
            {/* [ Header ] end */}

            {/* [ Main Content ] start */}
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            {/* [ breadcrumb ] start */}
                            <div className="page-header">
                                <div className="page-block">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="page-header-title">
                                                <h5 className="m-b-10">Goal Timing - Les challenges : Editer un challenge</h5>
                                            </div>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/"><i className="feather icon-home"></i></a></li>
                                                <li className="breadcrumb-item"><a href="/challenges/list">Challenges</a></li>
                                                <li className="breadcrumb-item"><a href="/challenge/edit">Edit Challenges</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* [ breadcrumb ] end */}
                            <FormChallenges datachallenge={this.state.event} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
      );
    }
  }
}

export default ChallengesCreate