import React, {Component} from 'react';
import DeleteChallenge from '../components/DeleteChallenge';
import ImportChallengeCsv from '../components/ImportChallengeCsv';
// import ShowInscription from '../components/ShowInscription';
import ShowSections from '../components/ShowSections';
import Message from '../components/Message';
import Header from '../components/Header';
import cookie from 'react-cookies';

class Challenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      tokenId: '',
      interpretedAlert: null,
      showDeclarative: false
    };
  }

  componentDidMount() {
    fetch("https://api.goaltiming.be/challenges?type=all")
      .then(res => res.json())
      .then(
        (result) => {
            console.log(result)

            const sortedChallenges = result.sort((a, b) => a.date - b.date);
            const selectedChallenges = [];

            for (var i = 0; i<(result.length); i++) {
              var challengeDate = this.convertDate(sortedChallenges[i].date);
              var publicDate = this.convertDate(sortedChallenges[i].publicationDate);

              selectedChallenges.push({
                name : sortedChallenges[i].name,
                date : challengeDate,
                publicationDate : publicDate,
                banner : sortedChallenges[i].banner,
                id : sortedChallenges[i].ChallengeId
              });
            }

          this.setState({
            isLoaded: true,
            items: selectedChallenges
          });

        },(error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
    let cookies = cookie.loadAll();

    if(!cookies.tokenId){
        window.location.replace("https://auth.goaltiming.be/login?client_id=1n014khs8r0ghlvuvg7vds0tbg&response_type=token&scope=email%20openid&redirect_uri=https%3A%2F%2Fadmin.goaltiming.be%2Fview&fbclid=IwAR02CuBWOwLypoSCXUZ_MPuHVGTK7KUyg2cmEOA1JDsrUZgM8KnM36PV6gA");
    }else{
        this.setState({
            isLoaded: true,
            tokenId: cookies.tokenId
        });
    }
  }

  convertDate(d){
    var date = new Date(d*1000);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear();

    return day + '/' + month + '/' + year;
  }

  render() {
    const { error, isLoaded, items, tokenId } = this.state;
    const action = this.props.match.params.action;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded || tokenId === '') {
      return <div className="loader">Loading...</div>;
    } else {
      return (

        <div>
            {/* [ Header ] start */}
              <Header />
            {/* [ Header ] end */}

            {/* [ Main Content ] start */}
            <section className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            {/* [ breadcrumb ] start */}
                            <div className="page-header">
                                <div className="page-block">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="page-header-title">
                                                <h5 className="m-b-10">Goal Timing - Les challenges</h5>
                                            </div>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/"><i className="feather icon-home"></i></a></li>
                                                <li className="breadcrumb-item"><a href="/challenges/list">Challenges</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* [ breadcrumb ] end */}
                            <div className="main-body">     
                                <div className="page-wrapper">
                                    {/* [ Main Content ] start */}
                                    <div className="row">
                                        {/* [ stiped-table ] start */}
                                        <div className="col-xl-12">
                                            <div className="card">
                                              
                                                <Message datamesage={action} />

                                                <div className="card-header">
                                                    <h5>Gestion des challenges</h5>
                                                    <span className="d-block m-t-5"><a href="/challenge/create">+ ajouter un challenge  </a></span>
                                                </div>
                                                <div className="card-block table-border-style">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Titre</th>
                                                                    <th>Date de l'évènement</th>
                                                                    <th>Date de publication</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {items.map(function(item, i){
                                                                   return <tr key={i}>
                                                                            <th scope="row">{i+1}</th>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.date}</td>
                                                                            <td>{item.publicationDate}</td>
                                                                            <td>
                                                                              <a href={'/challenge/edit/' + item.id}>Editer</a>
                                                                               &nbsp;| &nbsp;<ImportChallengeCsv dataid={item.id} token={tokenId} />
                                                                              &nbsp;<ShowSections dataid={item.id} token={tokenId} />

                                                                              <DeleteChallenge dataid={item.id} token={tokenId} />
                                                                            </td>
                                                                          </tr>;
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* [ stiped-table ] end */}
                                    </div>
                                    {/* [ Main Content ] end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
      );
    }
  }
}

export default Challenge