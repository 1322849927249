import React, {Component} from 'react';

class Header extends Component {

    render() {

        return(
            <header className="navbar pcoded-header navbar-expand-lg navbar-light">
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav ml-auto">
                        <li>
                            <div>
                                <a href="/view">
                                    Évènements
                                </a>
                                &nbsp;-&nbsp;
                                <a href="/challenges/view">
                                    Challenges 
                                </a>
                                &nbsp;&nbsp;&nbsp;
                                <i className="icon feather icon-settings"></i>
                                <div className="dropdown-menu dropdown-menu-right profile-notification">
                                    <div className="pro-head">
                                        <span>John Doe</span>
                                        <a href="auth-signin.html" className="dud-logout" title="Logout">
                                            <i className="feather icon-log-out"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </header>
        );
    }
}

export default Header