import React, {Component} from 'react';
import cookie from 'react-cookies';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidMount() {

    let cookies = cookie.loadAll();

    if(!cookies.tokenId){
      if(this.getAllUrlParams('id_token') !== undefined){
        let d = new Date();
        let tid = this.getAllUrlParams('id_token');
        d.setTime(d.getTime() + (55*60*1000));
        cookie.save("tokenId", tid, {path: "/", expires: d});

        console.log('okidoki');
      }else{
        console.log('error');
      }
    }else{
      console.log('okidoki');
    }
    
  }

  getAllUrlParams(param) {
    var url = window.location.hash;
    var selected = url.split(param + '=');

    var value = selected[1].split('&');
    
    return value[0];
  }
  

  render() {
    const { error } = this.state;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else {
      return (

        <div className="auth-wrapper">
            <div className="auth-content">
                <div className="auth-bg">
                    <span className="r"></span>
                    <span className="r s"></span>
                    <span className="r s"></span>
                    <span className="r"></span>
                </div>
                <div className="card">
                    <div className="card-body text-center">
                        <div className="mb-4">
                            <i className="feather icon-unlock auth-icon"></i>
                        </div>
                        <h3 className="mb-4">Login</h3>
                        <div className="input-group mb-3">
                            <input type="email" className="form-control" placeholder="Email" />
                        </div>
                        <div className="input-group mb-4">
                            <input type="password" className="form-control" placeholder="password" />
                        </div>
                        <button className="btn btn-primary shadow-2 mb-4">Login</button>
                    </div>
                </div>
            </div>
        </div>
        
      );
    }
  }
}

export default Login