import React, {Component} from 'react';

class ShowSections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            imgurl: '',
            sections: [],
            id: this.props.dataid
        };
        this.showSections = this.showSections.bind(this);
    }

    showSections() {
        fetch("https://api.goaltiming.be/challenges/" + this.state.id + '/admin/',{   
          headers: new Headers({
            'Authorization': this.props.token
          }), 
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log(result.sections)
                this.setState({sections: result.sections});
            },(error) => {
                console.log(error);
            }
        )
    }

    handleChange(e){
        e.preventDefault();
        console.log(e.target);
    }

    render() {
        const { error, sections, id } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div>
                    <button onClick={this.showSections} className="btnShowInscription">Voir les sections</button>
                    <ul>
                        {sections.map(function(section, i){
                            return <li key={i}><a href={'/challenger/'+id+'/sections/'+section.id+'/view'}>{section.name}</a></li>;
                        })}
                    </ul>
                </div>
            );
        }
    }
}

export default ShowSections