import React, {Component} from 'react';
import Geocode from 'react-geocode';

class Coordinates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            address: {},
            latitude: 50.4167,
            longitude: 4.4333
        };
        this.ref = this.props.dataregister;
        this.handleChange = this.handleChange.bind(this);
        this.getGeocode = this.getGeocode.bind(this);
    }
    componentDidMount(){
        var data = this.props;
        
        if(data.datalat){
            this.setState({latitude: data.datalat});
        }
        if(data.datalon){
            this.setState({longitude: data.datalon});
        }
        if(data.dataaddress){
            var splitedAddress = data.dataaddress.split('<br />');
            var splitedCountry = splitedAddress[1].split(' ');
            var cp = splitedCountry[0];
            splitedCountry.shift();

            this.setState({address: {
                adresse: splitedAddress[0],
                ville: splitedCountry.join().replace(',', ' '),
                cp: cp,
                pays: splitedAddress[2],
                place: data.dataplace
            }});
        }
    }

    handleChange = function(e){
        this.state.address[e.target.name] = e.target.value;
    }

    getGeocode = function(e){
        e.preventDefault();
        var completeAddress = this.state.address.adresse + ', ' +  this.state.address.cp + ' ' + this.state.address.ville + ', ' + this.state.address.pays;

        Geocode.setApiKey("AIzaSyA0jNW3jU7y0i1_kG4cW-YrPR7ixrFZ4LI"); 
        Geocode.fromAddress(completeAddress).then(
            response => {
            const { lat, lng } = response.results[0].geometry.location;
                this.setState({latitude: lat});
                this.setState({longitude: lng});
            },
            error => {
                console.error(error);
            }
        );
    }

    render() {
        const { error } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div className="card">
                    <div className="card-header">
                        <h5>Adresse</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Place</label>
                                    <input type="text" className="form-control" name="place" placeholder="Place" defaultValue={this.state.address.place} onChange={this.handleChange} ref={this.ref} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Rue et numéro</label>
                                    <input type="text" className="form-control" name="adresse" placeholder="Numéro et rue" defaultValue={this.state.address.adresse} onChange={this.handleChange} ref={this.ref} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Ville</label>
                                    <input type="text" className="form-control" name="ville" placeholder="Ville" defaultValue={this.state.address.ville} onChange={this.handleChange} ref={this.ref} />
                                </div>
                                <div className="form-group">
                                    <label>Pays</label>
                                    <input type="text" className="form-control" name="pays" placeholder="Pays" defaultValue={this.state.address.pays} onChange={this.handleChange} ref={this.ref} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Code Postal</label>
                                    <input type="text" className="form-control" name="cp" placeholder="Code Postal" defaultValue={this.state.address.cp} onChange={this.handleChange} ref={this.ref} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <button className="btn btn-primary" onClick={this.getGeocode}>Générer les coordonnées</button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Latitude</label>
                                    <input type="text" readOnly className="form-control" name="latitude" value={this.state.latitude} ref={this.ref} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Longitude</label>
                                    <input type="text" readOnly className="form-control" name="longitude" value={this.state.longitude} ref={this.ref} />
                                </div>
                            </div>                                                        
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Coordinates