import React, {Component} from 'react';

class Message extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            imgurl: ''
        };
        this.message = this.props.datamesage;
        this.sendMessage = this.sendMessage.bind(this);
    }

    sendMessage = function(e){
        switch(this.message){
            case 'delete':
                return '<p class="msg-success">Suppression réussie.</p>'
            break;
            case 'created':
                return '<p class="msg-success">Création réussie.</p>'
            break;
            case 'edit':
                return '<p class="msg-success">Edition réussie.</p>'
            break;
            default:
                return ''
            break;
        }
    }

    render() {
        const { error } = this.state;
        const message = this.sendMessage();

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div>
                    <div dangerouslySetInnerHTML={{__html: message}} />
                </div>
            );
        }
    }
}

export default Message