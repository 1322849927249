import React, {Component} from 'react';
import axios from 'axios';

class ImportCsv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            imgurl: ''
        };
        this.id = this.props.dataid;
        this.importCsv = this.importCsv.bind(this);
    }

    importCsv(e) {
        e.preventDefault();
        
        fetch('https://api.goaltiming.be/events/' + this.id + '/export', {
            method: 'GET',
            headers: new Headers({
                'Authorization': this.props.token
            }), 
        })
        .then(function (response) {
            response.text().then(function(body) {
                var a = document.createElement('a');
                a.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + body);
                a.setAttribute('download', 'export.csv');
                a.click();
                
            });
        })
    }

    render() {
        const { error } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <a href="/" onClick={this.importCsv}>Exporter</a>
            );
        }
    }
}

export default ImportCsv