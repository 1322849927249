import React, {Component} from 'react';
import swal from 'sweetalert';

class ShowInscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            imgurl: ''
        };
        this.id = this.props.dataid;
        this.showInscription = this.showInscription.bind(this);
    }

    showInscription(e) {
        e.preventDefault();

        fetch("https://api.goaltiming.be/events/" + this.id + '/admin/',{
          headers: new Headers({
            'Authorization': this.props.token
          }), 
        })
        .then(res => res.json())
        .then(
            (result) => {
              var countTotal = 0;
              var countInscrition = 0;
              for(var i=0; i<result.races.length; i++){
                var item = result.races[i];
                countTotal = Number(countTotal) + Number(item.maxParticipants);
                countInscrition = Number(countInscrition) + Number(item.countParticipants);
              }
              swal({
                  title: "Inscriptions: ",
                  text: countInscrition + ' / ' + countTotal + '<a href="#">Hello</a>',
                  icon: "info",
                  button: false,
                  timer: 3000
              })
            },(error) => {
                console.log(error);
            }
        )
    }

    render() {
        const { error } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <button onClick={this.showInscription} className="btnShowInscription">Inscriptions</button>
            );
        }
    }
}

export default ShowInscription