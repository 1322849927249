import React from 'react';
import useForm from 'react-hook-form';

let backupItem = [];
let backupCustoms = [];

export default function FormChallenger(props) {
    const { register, handleSubmit, errors } = useForm();
    const challengeid = props.challengeid;
    const sectionid = props.sectionid;
    let challenger = props.challenger || {items: [], customFields: []};
    const items = props.items;
    const type = props.type;
    const customfields = props.customfields || [];


    if(challenger.customFields === undefined){
        challenger.customFields = [null]
    }
    
    for(var i=0; i<customfields.length; i++){
        if(challenger.customFields[i] === null || challenger.customFields[i] === undefined){
            challenger.customFields[i] = {};
            challenger.customFields[i].name = customfields[i].label;
            challenger.customFields[i].value = '';
            challenger.customFields[i].id = customfields[i].id;
        }else{
            backupCustoms[i] = challenger.customFields[i];
        }
    }


    if(challenger.items == undefined){
        challenger.items = []
    }

    for(var i=0; i<items.length; i++){
        if(challenger.items[i] === null || challenger.items[i] === undefined){
            challenger.items[i] = {};
            challenger.items[i].name = items[i].name;
            challenger.items[i].price = items[i].price;
            challenger.items[i].ItemId = items[i].ItemId;
            challenger.items[i].number = 0;
            challenger.items[i].totalprice = 0;
        }
    }

    if(challenger.payment == undefined){
        var date = new Date();
        var timestamp = date.getTime();

        challenger.payement = {
            date: timestamp/1000,
            mode: "Created by admin",
            promoCode: false
        };
    }

    const onSubmit = function(d){
        if(type === 'create'){
            fetch('https://api.goaltiming.be/challengers/new', {method: 'GET'})
            .then(res => res.json())
            .then(
                (result) => {
                    // challenger = result;
                    // console.log(result)
                    if(d.sex !== ""){
                        challenger.sex = d.sex;
                    }
                    challenger.name = d.name;
                    challenger.firstname = d.firstname;
                    challenger.email = d.email;
                    challenger.dob = d.dob;
                    challenger.address = d.address;
                    challenger.country = d.country;
                    challenger.club = d.club;
                    challenger.items = backupItem;
                    challenger.customFields = backupCustoms;
                    challenger.dossard = d.dossard;
                    challenger.note = d.note;
        
                    var date = new Date();
                    var timestamp = date.getTime();
                    challenger.accept_rgpd = true;
                    challenger.accept_newsletter = false;
                    challenger.accept_rules = true;
                    challenger.is_in_good_health = true;
                    challenger.payement_status = 'success';
                    challenger.payement = {
                        date: timestamp/1000,
                        mode: "Created by admin",
                        promoCode: false
                    };


                    var xhr = new XMLHttpRequest()
                        xhr.open('POST', "https://api.goaltiming.be/challengers/challenges/" + challengeid + '/sections/' + sectionid + '/admin')
                        xhr.setRequestHeader('Authorization', props.token)
                        xhr.send(JSON.stringify(challenger))
                        xhr.onreadystatechange = function() {
                            if (xhr.readyState === 4) {
                                window.location.replace('/challenger/'+ challengeid +'/sections/'+ sectionid +'/created');
                            }
                        }

                },(error) => {
                    console.log(error);
                }
            )

            
        }else{
            if(d.sex !== ""){
                challenger.sex = d.sex;
            }
            challenger.name = d.name;
            challenger.firstname = d.firstname;
            challenger.email = d.email;
            challenger.dob = d.dob;
            challenger.address = d.address;
            challenger.country = d.country;
            challenger.club = d.club;
            challenger.items = backupItem;
            challenger.customFields = backupCustoms;
            challenger.dossard = d.dossard;
            challenger.note = d.note;
            challenger.payement.mode = d.payement.mode;

            var xhr = new XMLHttpRequest()
                xhr.open('PATCH', "https://api.goaltiming.be/challengers/" + challenger.ChallengerId + "/challenges/" + challengeid + '/sections/' + sectionid)
                xhr.setRequestHeader('Authorization', props.token)
                xhr.send(JSON.stringify(challenger))
                xhr.onreadystatechange = function() {
                    if (xhr.readyState === 4) {
                        window.location.replace('/challenger/'+ challengeid +'/sections/'+ sectionid +'/edit');
                    }
                }
        }
        
    }
    
    const handleChange = function(e){
        e.preventDefault();

        if(e.target.attributes.datatype.nodeValue === 'custom'){
            backupCustoms[e.target.attributes.datakey.nodeValue] = {
                name: customfields[e.target.attributes.datakey.nodeValue].label,
                value: e.target.value,
                id: customfields[e.target.attributes.datakey.nodeValue].id
            };
        }else{
            if(e.target.value < 0){
                e.target.value = 0;
            }
    
            backupItem[e.target.attributes.datakey.nodeValue] = {
                number: Math.round(e.target.value),
                name: e.target.attributes.dataname.nodeValue,
                price: e.target.attributes.dataprice.nodeValue,
                totalprice: e.target.value*e.target.attributes.dataprice.nodeValue,
                ItemId: items[e.target.attributes.datakey.nodeValue].ItemId
            };
        }

        console.log(backupItem)
    }

    const convertToDate = function(date){
        if(date){
            if(date.includes('/')){
                var split = date.split('/');
                return split[2] + '-' + split[1] + '-' + split[0];
            }else{
                return date;
            }
        }
    }

    return (
        <form className="main-body" onSubmit={handleSubmit(onSubmit)}>
            <div className="page-wrapper">
                {/* [ Main Content ] start */}
                <div className="row">
                    <div className="col-sm-12">

                        {/* [Infos] START */}
                        <div className="card">
                            <div className="card-header">
                                <h5>Informations sur le challenger</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Nom *</label>
                                            <input type="text" className="form-control" name="name" defaultValue={challenger.name} placeholder="Nom" ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.name && <span>Ce champ est requis</span>}</small>
                                        </div>
                                        <div className="form-group">
                                            <label>Sexe *</label>
                                            {challenger.sex === 'H' ? (
                                                    <div className="row">
                                                        <div className="col"><input type="radio" name="sex" value="F" ref={register} /> Femme</div>
                                                        <div className="col"><input type="radio" name="sex" value="H" defaultChecked ref={register} /> Homme</div>
                                                    </div>
                                                ) : (
                                                    <div className="row">
                                                        <div className="col"><input type="radio" name="sex" value="F" defaultChecked ref={register} /> Femme</div>
                                                        <div className="col"><input type="radio" name="sex" value="H" ref={register} /> Homme</div>
                                                    </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Prénom *</label>
                                            <input type="text" className="form-control" name="firstname" defaultValue={challenger.firstname} placeholder="Prénom" ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.firstname && <span>Ce champ est requis</span>}</small>
                                        </div>
                                        <div className="form-group">
                                            <label>Date de naissance *</label>
                                            <input type="date" className="form-control" name="dob" defaultValue={convertToDate(challenger.dob)} ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.dob && <span>Ce champ est requis</span>}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Email *</label>
                                            <input type="text" className="form-control" name="email" placeholder="email@email.be" defaultValue={challenger.email} ref={register({required: true, pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }})} />
                                            <small className="form-text form-error">{errors.email && <span>Ce champ est requis</span>}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Adresse *</label>
                                            <input type="text" className="form-control" name="address" placeholder="Adresse..." defaultValue={challenger.address} ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.address && <span>Ce champ est requis</span>}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Pays *</label>
                                            <input type="text" className="form-control" name="country" placeholder="Pays" defaultValue={challenger.country} ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.country && <span>Ce champ est requis</span>}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Equipe</label>
                                            <input type="text" className="form-control" name="club" placeholder="club" defaultValue={challenger.club} ref={register} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Dossard</label>
                                            <input type="text" className="form-control" name="dossard" placeholder="dossard" defaultValue={challenger.dossard} ref={register} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Notes</label>
                                            <input type="text" className="form-control" name="note" placeholder="Notes..." defaultValue={challenger.note} ref={register} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Mode de paiement</label>
                                            <input type="text" className="form-control" name="payement.mode" placeholder="src_" defaultValue={challenger.payement.mode} ref={register} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* [Infos] END */}

                        {/* [Item] START */}
                        <div className="card">
                            <div className="card-header">
                                <h5>Les objets</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                    {challenger.items.map((item, i) =>
                                        <div key={i}>
                                            {item.name} ({item.price} €) <input type="number" step="1" min="0" dataname={item.name} datakey={i} dataprice={item.price}  defaultValue={item.number} className="form-control" datatype="item" onChange={handleChange} />
                                            <hr />
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* [Items] END */}

                        {/* [Customs] START */}
                        <div className="card">
                            <div className="card-header">
                                <h5>Les customs</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                    {customfields.map((field, i) =>
                                        <div key={i}>
                                            {field.label} <input type="text" datakey={i} defaultValue={challenger.customFields[i].value} datatype="custom" className="form-control" onChange={handleChange} />
                                            <hr />
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* [Customs] END */}

                        {/* [Publication] START */}
                        <div className="card">
                            <div className="card-header">
                                <h5>Information de publication</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <button className="btn btn-primary" type="submit">Enregistrer</button>
                                        </div>
                                    </div>                                                       
                                </div>
                            </div>
                        </div>
                        {/* [Publication] END */}

                    </div>
                </div>
                {/* [ Main Content ] end */}
            </div>
        </form>
    );
}