import React, {Component} from 'react';
import swal from 'sweetalert';

class DeleteChallenger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            imgurl: ''
        };
        this.challengeid = this.props.challengeid;
        this.sectionid = this.props.sectionid;
        this.challengerid = this.props.challengerid;
        this.name = this.props.name
        this.deleteChallenger = this.deleteChallenger.bind(this);
    }

    deleteChallenger(e) {
        console.log(this.props);
        e.preventDefault();
        swal({
          title: "Suppression du challenger",
          text: "Voulez-vous supprimer " + this.name,
          icon: "warning",
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
            fetch('https://api.goaltiming.be/challengers/' + this.challengerid + '/challenges/' + this.challengeid + '/sections/' + this.sectionid, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': this.props.token
                }), 
            })
            .then(res => res.json())
            .then(
                (result) => {
                    window.location.replace('/challenger/'+ this.challengeid +'/sections/'+ this.sectionid +'/delete');
                },(error) => {
                    console.log(error);
                }
            )
          }
        });
    }

    render() {
        const { error } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div>
                    <button onClick={this.deleteChallenger} className="btnDelete">Supprimer le challenger</button>
                </div>
            );
        }
    }
}

export default DeleteChallenger