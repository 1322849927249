import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import Login from './pages/Login';
import Events from './pages/Events';
import EventsCreate from './pages/EventsCreate';
import EventsEdit from './pages/EventsEdit';
import Registered from './pages/Registered';
import RegisteredEdit from './pages/RegisteredEdit';
import RegisteredCreate from './pages/RegisteredCreate';
import Challenges from './pages/Challenges';
import ChallengesCreate from './pages/ChallengesCreate';
import ChallengesEdit from './pages/ChallengesEdit';
import Challenger from './pages/Challenger';
import ChallengerEdit from './pages/ChallengerEdit';
import ChallengerCreate from './pages/ChallengerCreate';
// import Notfound from './pages/Notfound';
// import * as serviceWorker from './serviceWorker';

const routing = (
  <Router>
    <div>
      <Route exact path="/:action" component={Events} />
      <Route path="/events/create" component={EventsCreate} />
      <Route path="/events/edit/:id" component={EventsEdit} />
      <Route path="/registered/:eventid/races/:raceid/:action" component={Registered} />
      <Route path="/registered/:registeredid/event/:eventid/races/:raceid" component={RegisteredEdit} />
      <Route path="/registered/create/:eventid/races/:raceid" component={RegisteredCreate} />
      <Route path="/challenges/:action" component={Challenges} />
      <Route path="/challenge/create" component={ChallengesCreate} />
      <Route path="/challenge/edit/:id" component={ChallengesEdit} />
      <Route path="/challenger/:challengeid/sections/:sectionid/:action" component={Challenger} />
      <Route path="/challengers/:challengerid/challenges/:challengeid/sections/:sectionid" component={ChallengerEdit} />
      <Route path="/challengers/challenges/:challengeid/sections/:sectionid" component={ChallengerCreate} />
    </div>
  </Router>
)
      // <Route component={Notfound} />

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
