import React, {Component} from 'react';
import DeleteRegistered from '../components/DeleteRegistered';
import ImportCsv from '../components/ImportCsv';
import ShowInscription from '../components/ShowInscription';
import Message from '../components/Message';
import Header from '../components/Header';
import cookie from 'react-cookies';

class Registered extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      eventName: '',
      raceName: '',
      interpretedAlert: null,
      showDeclarative: false,
      eventId: this.props.match.params.eventid,
      raceId: this.props.match.params.raceid,
      tokenId: ''
    };
  }

  componentDidMount() {

    let cookies = cookie.loadAll();

    if(!cookies.tokenId){
        window.location.replace("https://auth.goaltiming.be/login?client_id=1n014khs8r0ghlvuvg7vds0tbg&response_type=token&scope=email%20openid&redirect_uri=https%3A%2F%2Fadmin.goaltiming.be%2Fview&fbclid=IwAR02CuBWOwLypoSCXUZ_MPuHVGTK7KUyg2cmEOA1JDsrUZgM8KnM36PV6gA");
    }else{
        fetch("https://api.goaltiming.be/participants/events/"+this.props.match.params.eventid+"/races/"+this.props.match.params.raceid, {
            method: 'GET',
            headers: new Headers({
                'Authorization': cookies.tokenId
            }), 
        })
        .then(res => res.json())
        .then(
        (result) => {
            fetch("https://api.goaltiming.be/events/" + this.props.match.params.eventid+ '/admin/',{
            headers: new Headers({
                'Authorization': cookies.tokenId
            }), 
            })
            .then(res => res.json())
            .then(
                (event) => {
                    // CREATE REGISTERED LIST
                    const sortedRegisterd = result.sort((a, b) => a.date - b.date);
                    const selectedRegistered = [];

                    for (var i = 0; i<(result.length); i++) {
                        var creationDate = this.convertDate(sortedRegisterd[i].creationDate);

                        selectedRegistered.push({
                            name : sortedRegisterd[i].name + ' ' + sortedRegisterd[i].firstname,
                            email: sortedRegisterd[i].email,
                            date : creationDate,
                            id : sortedRegisterd[i].ParticipantId
                        });
                    }

                    // GET EVENT DATAS
                    for(var i =0; i<event.races.length; i++){
                        if(event.races[i].id === this.props.match.params.raceid){
                            this.setState({raceName: event.races[i].name});
                        }
                    }

                    // SET DATAS
                    this.setState({
                        isLoaded: true,
                        eventName: event.name,
                        registerd: selectedRegistered,
                        tokenId: cookies.tokenId
                    });
                    
                },(error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
            
            },(error) => {
            this.setState({
                isLoaded: true,
                error
            });
            }
        )
    }
  }

  convertDate(d){
    var date = new Date(d*1000);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear();

    return day + '/' + month + '/' + year;
  }

  render() {
    const { error, isLoaded, registerd, tokenId, eventId, raceId } = this.state;
    const action = this.props.match.params.action;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded || tokenId === '') {
      return <div className="loader">Loading...</div>;
    } else {
      return (

        <div>
            {/* [ Header ] start */}
              <Header />
            {/* [ Header ] end */}

            {/* [ Main Content ] start */}
            <section className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            {/* [ breadcrumb ] start */}
                            <div className="page-header">
                                <div className="page-block">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="page-header-title">
                                                <h5 className="m-b-10">Goal Timing - {this.state.eventName}</h5>
                                            </div>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/view"><i className="feather icon-home"></i></a></li>
                                                <li className="breadcrumb-item"><a>Inscrits</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* [ breadcrumb ] end */}
                            <div className="main-body">     
                                <div className="page-wrapper">
                                    {/* [ Main Content ] start */}
                                    <div className="row">
                                        {/* [ stiped-table ] start */}
                                        <div className="col-xl-12">
                                            <div className="card">
                                              
                                                <Message datamesage={action} />

                                                <div className="card-header">
                                                    <h5>Course : {this.state.raceName}</h5>
                                                    <span className="d-block m-t-5"><a href={'/registered/create/'+this.props.match.params.eventid+'/races/'+this.props.match.params.raceid}>+ ajouter un participant  </a></span>
                                                </div>
                                                <div className="card-block table-border-style">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Nom</th>
                                                                    <th>Email</th>
                                                                    <th>Date d'inscription</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {registerd.map(function(item, i){
                                                                   return <tr key={i}>
                                                                            <th scope="row">{i+1}</th>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.email}</td>
                                                                            <td>{item.date}</td>
                                                                            <td>
                                                                                <a href={'/registered/'+item.id+'/event/'+eventId+'/races/' + raceId}>Editer</a>
                                                                                <DeleteRegistered registeredid={item.id} eventid={eventId} raceid={raceId} name={item.name} token={tokenId} />
                                                                            </td>
                                                                          </tr>;
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* [ stiped-table ] end */}
                                    </div>
                                    {/* [ Main Content ] end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
      );
    }
  }
}

export default Registered