import React, {Component} from 'react';
import swal from 'sweetalert';

class DeleteRegistered extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            imgurl: ''
        };
        this.eventid = this.props.eventid;
        this.raceid = this.props.raceid;
        this.registeredid = this.props.registeredid;
        this.name = this.props.name
        this.deleteRegistered = this.deleteRegistered.bind(this);
    }

    deleteRegistered(e) {
        console.log(this.props);
        e.preventDefault();
        swal({
          title: "Suppression du participant",
          text: "Voulez-vous supprimer " + this.name,
          icon: "warning",
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
            fetch('https://api.goaltiming.be/participants/' + this.registeredid + '/events/' + this.eventid + '/races/' + this.raceid, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': this.props.token
                }), 
            })
            .then(res => res.json())
            .then(
                (result) => {
                    window.location.replace('/registered/'+ this.eventid +'/races/'+ this.raceid +'/delete');
                },(error) => {
                    console.log(error);
                }
            )
          }
        });
    }

    render() {
        const { error } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div>
                    <button onClick={this.deleteRegistered} className="btnDelete">Supprimer le participant</button>
                </div>
            );
        }
    }
}

export default DeleteRegistered