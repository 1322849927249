import React, {Component} from 'react';

class UploadTinyMce extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            imgurl: ''
        };
        this.ref = this.props.dataref;
        this.errors = this.props.dataerrors;
        this.name = this.props.dataname;
        this.token = this.props.datatoken;
        this.uploadImg = this.uploadImg.bind(this);
    }

    componentDidMount(){
        var data = this.props;

        if(data.dataimg){
            this.setState({imgurl: data.dataimg});
        }
    }
    
    uploadImg = function(e){
        var img = e.target.files[0];

        fetch('https://api.goaltiming.be/upload', {
            method: 'POST',
            headers: new Headers({
                'Content-Type': img.type,
                'Authorization': this.token
            }), 
            body: img
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({imgurl: result.fileUrl});
            },(error) => {
                console.log(error);
            }
        )
    }

    render() {
        const { error } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div>
                    <input type="file" className="form-control" name={this.name} onChange={this.uploadImg} />
                    <input type="hidden" className="form-control" name={this.name} value={this.state.imgurl} ref={this.ref} />
                    <div>https://www.goaltiming.be{this.state.imgurl}</div>
                </div>
            );
        }
    }
}

export default UploadTinyMce