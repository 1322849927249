import React, {Component} from 'react';
import swal from 'sweetalert';

class DeleteChallenge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            imgurl: ''
        };
        this.id = this.props.dataid;
        this.deleteChallenge = this.deleteChallenge.bind(this);
    }

    deleteChallenge(e) {
        e.preventDefault();
        swal({
          title: "Suppression de challenge",
          text: "Voulez-vous supprimer le challenge ?",
          icon: "warning",
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
            fetch('https://api.goaltiming.be/challenges/' + this.id, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': this.props.token
                }), 
            })
            .then(res => res.json())
            .then(
                (result) => {
                    window.location.replace('/challenges/delete');
                },(error) => {
                    console.log(error);
                }
            )
          }
        });
    }

    render() {
        const { error } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div>
                    <button onClick={this.deleteChallenge} className="btnDelete">Supprimer le challenge</button>
                </div>
            );
        }
    }
}

export default DeleteChallenge