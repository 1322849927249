import React, {Component} from 'react';

class Items extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            items: []
        };
        this.ref = this.props.dataref;
        this.errors = this.props.dataerrors;
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.token = this.props.datatoken;
    }

    componentDidMount(){
        var data = this.props;

        if(data.dataitems){
            // for(var i=0; i<data.dataitems.length; i++){
            //     data.dataitems[i].image = 'https://www.goaltiming.be' + data.dataitems[i].image;
            // }
            this.setState({items: data.dataitems});
        }
    }

    handleChange = function(e){
        var target = e.target.attributes.data_target.nodeValue;
        var name = e.target.attributes.data_name.nodeValue;

        if(e.target.files != null){
            var img = e.target.files[0];
        
            fetch('https://api.goaltiming.be/upload', {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': img.type,
                    'Authorization': this.token
                }), 
                body: img
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.state.items[target][name] = result.fileUrl;
                    this.setState({ items: this.state.items });
                    this.props.callbackItem(this.state.items);
                },(error) => {
                    console.log(error);
                }
            )
        }else{
            this.state.items[target][name] = e.target.value;
            this.setState({ items: this.state.items });
            this.props.callbackItem(this.state.items);
        }
    }
    
    addItem = function(e){
        e.preventDefault();
        fetch('https://api.goaltiming.be/items/new', {
            method: 'GET',
            headers: new Headers({
                'Authorization': this.token
            }), 
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({ items: this.state.items.concat([result]) })
                this.props.callbackItem(this.state.items);
            },(error) => {
                console.log(error);
            }
        )
    }

    removeItem = function(e){
        e.preventDefault();

        this.state.items.splice(e.target.attributes.data_target.nodeValue,1);
        this.setState({ items: this.state.items });
        this.props.callbackItem(this.state.items);
    }

    render() {
        const { error } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div className="card-body">
                    {this.state.items.map((item, i) =>
                        <div key={i}> 
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Nom de l'objet</label>
                                        <input type="text" className="form-control" value={this.state.items[i].name} name={'item_' + i + '_name'} data_target={i} data_name="name" placeholder="Nom de l'objet" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['item_' + i + '_name'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                </div>    
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Info bulle</label>
                                        <input type="text" className="form-control" value={this.state.items[i].tooltip} name={'item_' + i + '_tooltip'} data_target={i} data_name="tooltip" placeholder="Info bulle" ref={this.ref} onChange={this.handleChange} />
                                    </div>
                                </div>    
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Prix</label>
                                        <input type="number" min="0" step="0.1" className="form-control" value={this.state.items[i].price} name={'item_' + i + '_price'} data_target={i} data_name="price" placeholder="0" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['item_' + i + '_price'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                </div>  
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Image</label>
                                        <input type="file" className="form-control" name={'item_' + i + '_image'} data_target={i} data_name="image" onChange={this.handleChange} />
                                        <input type="hidden" className="form-control" name={'item_' + i + '_image'} value={this.state.items[i].image} ref={this.ref} />
                                        <small className="form-text form-error">{this.errors['item_' + i + '_image'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                </div>  
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Course associée</label>
                                        <input type="text" className="form-control" value={this.state.items[i].link} name={'item_' + i + '_link'} data_target={i} data_name="link" placeholder="Course associée" ref={this.ref} onChange={this.handleChange} />
                                    </div>
                                </div> 
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Nombre maximum</label>
                                        <input type="number" min="0" step="1" className="form-control" value={this.state.items[i].max} name={'item_' + i + '_max'} data_target={i} data_name="max" ref={this.ref} onChange={this.handleChange} />
                                    </div>
                                </div> 
                                <div className="col-md-12">
                                    <a style={{float:'right'}} data_target={i} onClick={this.removeItem} href="/">Supprimer l'objet</a>
                                </div>
                            </div>
                            <hr />
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" onClick={this.addItem}>Ajouter un objet</button>
                            </div>
                        </div>   
                    </div>
                </div>
            );
        }
    }
}

export default Items