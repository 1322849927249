import React, {Component} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

class Sections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            customFields: [],
            sections: [],
            events: [],
            selectedEvents: []
        };
        this.ref = this.props.dataref;
        this.errors = this.props.dataerrors;
        this.addSection = this.addSection.bind(this);
        this.removeSection = this.removeSection.bind(this);
        this.addRed = this.addRed.bind(this);
        this.removeRed = this.removeRed.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.customChange = this.customChange.bind(this);
        this.token = this.props.datatoken;
    }

    componentDidMount(){
        var data = this.props;
        const arrayMonth = ['Janv.', 'Févr.', 'Mars', 'Avr.', 'Mai', 'Juin', 'Juill.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'];

        if(data.datasections){
            for(var i = 0; i < data.datasections.length; i++){
                if(data.datasections[i].customFields === undefined){
                    data.datasections[i].customFields = [];
                }else{
                    for(var j = 0; j < data.datasections[i].customFields.length; j++){
                        if(data.datasections[i].customFields[j].id === undefined){
                            data.datasections[i].customFields[j].id = this.randomCode(30);
                        }
                        
                    }
                }

                if(data.datasections[i].events === undefined){
                    data.datasections[i].events = [];
                }
            }
            this.setState({
                sections: data.datasections
            });
        }

        // CHARGEMENT DES EVENTS
        fetch("https://api.goaltiming.be/events?type=all")
            .then(res => res.json())
            .then(
            (result) => {
                let allEvents = []

                console.log(result)

                for(let i=0;i<result.length;i++){

                    var date = new Date(result[i].date*1000);
                    var day = date.getDate();
                    var month = date.getMonth()+1;
                    var year = date.getFullYear();
                    var buildedDate = day + ' ' + arrayMonth[month-1] + ' ' + year;


                    allEvents.push({
                        label: result[i].name + ' - ' + buildedDate,
                        name: result[i].name,
                        id: i,
                        eventid: result[i].EventId,
                        races: []
                    })
                }

                this.setState({
                    events: allEvents
                });

            },(error) => {
                this.setState({
                    error
                });
            }
        )
    }

    racesUpdate = function(i, j, target, value){

        this.state.sections[target]['events'][i].races[j].selected  = value;
        this.setState({ sections: this.state.sections });
        this.props.callbackSection(this.state.sections);
    }

    eventsUpdate = function(e, values, target){
        let updatedEvents = values;

        for(let i=0;i<values.length;i++){
            fetch("https://api.goaltiming.be/events/" + values[i].eventid)
                .then(res => res.json())
                .then(
                (result) => {
                    updatedEvents[i].date = result.date;
                    let races = []
                    for(let j=0;j<result.races.length;j++){
                        races.push({
                            label: result.races[j].name,
                            id: result.races[j].id,
                            selected: false,
                            date: result.date
                        })
                    }
                    updatedEvents[i].races = races
                    if(i >= (values.length-1)){
                        this.state.sections[target]['events'] = updatedEvents;
                        this.setState({ sections: this.state.sections });
                        this.props.callbackSection(this.state.sections);
                    }
                },(error) => {
                    this.setState({
                        error
                    });
                }
            )
        }
    }

    handleChange = function(e){
        var target = e.target.attributes.data_target.nodeValue;
        var name = e.target.attributes.data_name.nodeValue;
        
        if(e.target.files != null){
            var file = e.target.files[0];
        
            fetch('https://api.goaltiming.be/upload', {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': file.type,
                    'Authorization': this.token
                }), 
                body: file
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.state.sections[target][name] = result.fileUrl;
                    this.setState({ sections: this.state.sections });
                    this.props.callbackSection(this.state.sections);
                },(error) => {
                    console.log(error);
                }
            )
        }else{
            this.state.sections[target][name] = e.target.value;
            this.setState({ sections: this.state.sections });
            this.props.callbackSection(this.state.sections);
        }
    }
    
    addSection = function(e){
        e.preventDefault();
        fetch('https://api.goaltiming.be/events/races/new', {
            method: 'GET',
            headers: new Headers({
                'Authorization': this.token
            }), 
        })
        .then(res => res.json())
        .then(
            (result) => {
                result.events = [];
                this.setState({ sections: this.state.sections.concat([result]) })
                this.props.callbackSection(this.state.sections);
            },(error) => {
                console.log(error);
            }
        )
    }

    removeSection = function(e){
        e.preventDefault();

        this.state.sections.splice(e.target.attributes.data_target.nodeValue,1);
        this.setState({ sections: this.state.sections });
        this.props.callbackSection(this.state.sections);
    }

    addRed = function(e){
        e.preventDefault();
        var target = e.target.attributes.data_target.nodeValue;

        this.state.sections[target].customFields.push({
            id: this.randomCode(30),
            label: '',
            reduction: 0
        });

        this.setState({ sections: this.state.sections })
        this.props.callbackSection(this.state.sections);
    }

    removeRed = function(e){
        e.preventDefault();
        var target = e.target.attributes.data_target.nodeValue;
        var customField = e.target.attributes.data_custom.nodeValue;

        this.state.sections[target].customFields.splice(customField,1);
        this.setState({ sections: this.state.sections });
        this.props.callbackSection(this.state.sections);
    }

    customChange = function(e){
        var target = e.target.attributes.data_target.nodeValue;
        var name = e.target.attributes.data_name.nodeValue;
        var custom = e.target.attributes.data_custom.nodeValue;


        this.state.sections[target].customFields[custom][name] = e.target.value;
        this.setState({ sections: this.state.sections });
        this.props.callbackSection(this.state.sections);
    }

    randomCode(length = 10) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    convertToDate = function(timestamp){
        var date = new Date(timestamp*1000);
        var day = ("0" + date.getDate()).slice(-2)
        day = day < 10 ? '0'+day : day;
        day = day.length > 2 ? day.substr(1) : day;
        var month = date.getMonth()+1;
        month = month < 10 ? '0'+month : month;
        month = month.length > 2 ? month.substr(1) : month;
        var year = date.getFullYear();

        return year + "-" + month + "-" + day;
    }

    convertToTime = function(timestamp){
        var date = new Date(timestamp*1000);
        var hour = date.getHours();
        hour = hour < 10 ? '0'+hour : hour;
        var minutes = date.getMinutes();
        minutes = minutes < 10 ? '0'+minutes : minutes;

        return hour + ":" + minutes;
    }

    render() {
        const { error } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div className="card-body">

                    {this.state.sections.map((item, i) =>
                        <div key={i}> 
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Nom de la section</label>
                                        <input type="text" className="form-control" value={this.state.sections[i].name} name={'section_' + i + '_name'} data_target={i} data_name="name" placeholder="Nom de la section" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['section_' + i + '_name'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                </div>    
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Date de cloture des inscriptions</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="date" className="form-control" name={'section_' + i + '_close'} defaultValue={this.state.sections[i].close} data_target={i} data_name="close" ref={this.ref} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="time" className="form-control" name={'section_' + i + '_closeTime'} defaultValue={this.state.sections[i].closeTime} data_target={i} data_name="closeTime" ref={this.ref} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Prix</label>
                                        <input type="number" min="-1" className="form-control" value={this.state.sections[i].price} name={'section_' + i + '_price'} data_target={i} data_name="price" placeholder="0" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['section_' + i + '_price'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Distance</label>
                                        <input type="number" min="0" className="form-control" value={this.state.sections[i].distance} name={'section_' + i + '_distance'} data_target={i} data_name="distance" placeholder="0" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['section_' + i + '_distance'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Nombre de challengers maximum</label>
                                        <input type="number" min="0" className="form-control" value={this.state.sections[i].maxParticipants} name={'section_' + i + '_maxParticipants'} data_target={i} data_name="maxParticipants" placeholder="0" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['section_' + i + '_maxParticipants'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                </div>  
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Prix en cas de pré-enregistrement</label>
                                        <input type="number" min="-1" step="0.1" className="form-control" value={this.state.sections[i].preRegistrationPrices} name={'section_' + i + '_preRegistrationPrices'} data_target={i} data_name="preRegistrationPrices" placeholder="0" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['section_' + i + '_preRegistrationPrices'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Départ</label>
                                        <input type="text" className="form-control" value={this.state.sections[i].departure} name={'section_' + i + '_departure'} data_target={i} data_name="departure" placeholder="12h00" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['section_' + i + '_departure'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Résultats</label>
                                        <input type="file" className="form-control" name={'section_' + i + '_results'} data_target={i} data_name="results" onChange={this.handleChange} />
                                        <input type="hidden" className="form-control" name={'section_' + i + '_results'} ref={this.ref} />
                                        {/* <input type="hidden" className="form-control" name={'section_' + i + '_pdf'} value={this.state.items[i].image} ref={this.ref({required: true})} /> */}
                                        <small className="form-text form-error">{this.errors['section_' + i + '_results'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <a style={{float:'right'}} data_target={i} onClick={this.removeSection} href="/">Supprimer la course</a>
                                </div>
                            </div>
                            <div className="card-header">
                                <h5>Course(s) liée(s) : </h5>
                            </div>
                            <Autocomplete
                                id={'section_' + i + '_events'}
                                multiple
                                options={this.state.events}
                                sx={{ width: '100%' }}
                                defaultValue={this.state.sections[i].events}
                                renderInput={(params) => <TextField {...params} label="Entrez le nom des évènements" />}
                                onChange={(event, values) => {
                                    this.eventsUpdate(event, values, i)
                                }}
                            />
                            <br />
                            <FormGroup>
                                {this.state.sections[i].events.map((event, j) =>
                                    <div key={j}>
                                        {event.label}
                                        <br />
                                        {event.races.map((race, k) =>
                                            <div key={k}>
                                                <FormControlLabel 
                                                    control={<Checkbox defaultChecked={race.selected} />} 
                                                    label={race.label} 
                                                    onChange={(event, value) => {
                                                        this.racesUpdate(j, k, i, value)
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <hr />
                                    </div>
                                )}
                            </FormGroup>
                            
                            <div className="card-header">
                                <h5>Champs personnalisés</h5>
                            </div>
                            <br />
                            {item.customFields.map((cf, j) =>
                                <div key={j}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Label</label>
                                                <input type="text" className="form-control" value={item.customFields[j].label} name={'section_' + j + '_customFieldsLabel'} data_target={i} data_custom={j} data_name="label" ref={this.ref({required: true})} onChange={this.customChange} />
                                                <small className="form-text form-error">{this.errors['section_' + j + '_customFieldsLabel'] && <span>Ce champ est requis</span>}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Réduction associée</label>
                                                <input type="number" min="0" step="0.1" value={item.customFields[j].reduction} className="form-control" name={'section_' + j + '_customFieldsRed'} data_target={i} data_custom={j} data_name="reduction" ref={this.ref({required: true})} onChange={this.customChange} />
                                                <small className="form-text form-error">{this.errors['section_' + j + '_customFieldsRed'] && <span>Ce champ est requis</span>}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Valeurs</label>
                                                <textarea type="text" className="form-control" value={item.customFields[j].values} name={'section_' + j + '_customFieldsValues'} data_target={i} data_custom={j} data_name="values" ref={this.ref({required: false})} onChange={this.customChange}></textarea>
                                                <small className="form-text">A remplir pour créer une liste de sélection, une valeur par ligne.</small>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Info bulle</label>
                                                <input type="text" className="form-control" value={item.customFields[j].tooltip} name={'section_' + j + '_customFieldsTooltip'} data_target={i} data_custom={j} data_name="tooltip" placeholder="Info bulle" ref={this.ref} onChange={this.customChange} />
                                            </div>
                                        </div>   
                                    </div>
                                    <a style={{float:'right'}} data_target={i} data_custom={j} onClick={this.removeRed} href="/">Supprimer le champ</a>
                                    <br />
                                </div>
                            )}
                            <a style={{float:'right'}} data_target={i} onClick={this.addRed} href="/">Ajouter un champ</a>
                            <br />
                            <hr />
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" onClick={this.addSection}>Ajouter une section</button>
                            </div>
                        </div>   
                    </div>
                </div>
            );
        }
    }
}

export default Sections