import React, {Component} from 'react';

class PromosCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            promos: []
        };
        this.register = this.props.dataref;

        this.addPromo = this.addPromo.bind(this);
        this.removePromo = this.removePromo.bind(this);
        this.generateCodes = this.generateCodes.bind(this);
        this.dlCodes = this.dlCodes.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        this.setState({promos: this.props.datapromos});
    }

    handleChange = function(e){
        var target = e.target.attributes.data_target.nodeValue;
        var name = e.target.attributes.data_name.nodeValue;

        this.state.promos[target][name] = e.target.value;
        this.setState({ promos: this.state.promos });
    }

    addPromo = function(e){
        e.preventDefault();

        let promos = {
            name: '',
            prefix: '',
            number: 0,
            discount: 0,
            codes: [],
            generated: ''
        };
        this.setState({ promos: this.state.promos.concat([promos]) })
    }

    removePromo = function(e){
        e.preventDefault();

        this.state.promos.splice(e.target.attributes.data_target.nodeValue,1);
        this.setState({ promos: this.state.promos });
    }

    generateCodes = function(e){
        e.preventDefault();
        let target = e.target.attributes.data_target.nodeValue;
        let prefix = this.state.promos[target].prefix;
        let number = this.state.promos[target].number;
        let codes = []

        for(var i=0; i<number; i++){
            codes.push(prefix + '_' + this.randomCode(6));
        }

        this.state.promos[target].codes = codes;
        this.state.promos[target].generated = 'generated';
        this.setState({ promos: this.state.promos });

        this.props.callbackPromos(this.state.promos);
    }

    dlCodes = function(e){
        e.preventDefault();
        let target = e.target.attributes.data_target.nodeValue;
        let name = this.state.promos[target].name;
        let codes = this.state.promos[target].codes;
        let discount = this.state.promos[target].discount;
        let rows = [['Codes promo', 'Réduction']];

        for(var i=0; i<codes.length; i++){
            rows.push([codes[i], discount + ' €']);
        }
        
        let csvContent = "data:text/csv;charset=utf-8," 
            + rows.map(e => e.join(",")).join("\n");

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", name + ".csv");
        document.body.appendChild(link);

        link.click();
    }

    randomCode(length = 10) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
     
    render() {
        const { error } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div className="card-body">
                    {this.state.promos.map((item, i) =>
                        <div key={i}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Nom</label>
                                        <input type="text" className="form-control" value={this.state.promos[i].name} data_target={i} data_name="name" onChange={this.handleChange} />
                                        <small className="form-text text-muted">Les codes générés ne pourront pas être supprimé.</small>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Préfix</label>
                                        <input type="text" maxLength="10" value={this.state.promos[i].prefix} className="form-control" data_target={i} data_name="prefix" onChange={this.handleChange} />
                                        <small className="form-text text-muted">Encodez uniquement des lettres sans caractères spéciaux ni espace.</small>
                                    </div>
                                </div>                                                          
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Nombre de codes promo à générer</label>
                                        <input type="number" min="0" value={this.state.promos[i].number} className="form-control" placeholder="0" data_target={i} data_name="number" onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Réduction accordée</label>
                                        <input type="number" min="0" value={this.state.promos[i].discount} className="form-control" placeholder="0" data_target={i} data_name="discount" onChange={this.handleChange} />
                                    </div>
                                </div>                                                          
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <a style={{float:'right'}} data_target={i} onClick={this.generateCodes} href="/" className={'genBtn ' + this.state.promos[i].generated}>Générer les codes</a>
                                    <a style={{float:'right'}} data_target={i} onClick={this.dlCodes} href="/" className={'dlBtn ' + this.state.promos[i].generated}>Télécharger les codes</a>
                                
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" onClick={this.addPromo}>Ajouter une promo</button>
                            </div>
                        </div>   
                    </div>
                </div>
            );
        }
    }
}

export default PromosCode