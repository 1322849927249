import React, {Component} from 'react';

class Rankings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            imgurl: '',
            rankings: []
        };
        this.ref = this.props.dataref;
        this.errors = this.props.dataerrors;
        this.name = this.props.dataname;
        this.token = this.props.datatoken;
        this.handleChange = this.handleChange.bind(this);
        this.addClassement = this.addClassement.bind(this);
        this.removeClassement = this.removeClassement.bind(this);
    }

    componentDidMount(){
        var data = this.props;

        if(this.props.datarankings !== undefined){
            this.setState({rankings: this.props.datarankings});
        }else{
            this.setState({rankings: []});
        }


        if(data.dataimg){
            this.setState({imgurl: data.dataimg});
        }
    }

    addClassement = function(e){
        e.preventDefault();

        let rankings = {
            name: '',
            image: '',
        };
        this.setState({ rankings: this.state.rankings.concat([rankings]) })
    }

    removeClassement = function(e){
        e.preventDefault();

        this.state.rankings.splice(e.target.attributes.data_target.nodeValue,1);
        this.setState({ rankings: this.state.rankings });
        this.props.callbackRankings(this.state.rankings);
    }
    
    handleChange = function(e){
        var target = e.target.attributes.data_target.nodeValue;
        var name = e.target.attributes.data_name.nodeValue;

        if(e.target.files != null){
            var img = e.target.files[0];
        
            fetch('https://api.goaltiming.be/upload', {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': img.type,
                    'Authorization': this.token
                }), 
                body: img
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.state.rankings[target][name] = result.fileUrl;
                    this.setState({ rankings: this.state.rankings });
                    this.props.callbackRankings(this.state.rankings);
                },(error) => {
                    console.log(error);
                }
            )
        }else{
            this.state.rankings[target][name] = e.target.value;
            this.setState({ rankings: this.state.rankings });
            this.props.callbackRankings(this.state.rankings);
        }
    }

    render() {
        const { error } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div className="card-body">
                    {this.state.rankings.map((item, i) =>
                        <div key={i}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Nom de l'objet</label>
                                        <input type="text" className="form-control" value={this.state.rankings[i].name} name={'ranking_' + i + '_name'} data_target={i} data_name="name" placeholder="Nom du classement" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['ranking_' + i + '_name'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                </div>    
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Fichier</label>
                                        <input type="file" className="form-control" name={'ranking_' + i + '_image'} data_target={i} data_name="image" onChange={this.handleChange} />
                                        <input type="hidden" className="form-control" name={'ranking_' + i + '_image'} value={this.state.rankings[i].image} ref={this.ref} />
                                        <small className="form-text form-error">{this.errors['ranking_' + i + '_image'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                </div> 
                                <div className="col-md-12">
                                    <a style={{float:'right'}} data_target={i} onClick={this.removeClassement} href="/">Supprimer l'objet</a>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" onClick={this.addClassement}>Ajouter un classement</button>
                            </div>
                        </div>   
                    </div>
                </div>
            );
        }
    }
}

export default Rankings