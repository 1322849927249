import React, {Component} from 'react';

class Races extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            customFields: [],
            races: []
        };
        this.ref = this.props.dataref;
        this.errors = this.props.dataerrors;
        this.addRace = this.addRace.bind(this);
        this.removeRace = this.removeRace.bind(this);
        this.addRed = this.addRed.bind(this);
        this.removeRed = this.removeRed.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.customChange = this.customChange.bind(this);
        this.token = this.props.datatoken;
    }

    componentDidMount(){
        var data = this.props;

        if(data.dataraces){
            for(var i = 0; i < data.dataraces.length; i++){
                if(data.dataraces[i].customFields === undefined){
                    data.dataraces[i].customFields = [];
                }else{
                    for(var j = 0; j < data.dataraces[i].customFields.length; j++){
                        if(data.dataraces[i].customFields[j].id === undefined){
                            data.dataraces[i].customFields[j].id = this.randomCode(30);
                        }
                        
                    }
                }
            }
            this.setState({races: data.dataraces});
        }
    }

    

    handleChange = function(e){
        var target = e.target.attributes.data_target.nodeValue;
        var name = e.target.attributes.data_name.nodeValue;
        
        if(e.target.files != null){
            var file = e.target.files[0];
        
            fetch('https://api.goaltiming.be/upload', {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': file.type,
                    'Authorization': this.token
                }), 
                body: file
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.state.races[target][name] = result.fileUrl;
                    this.setState({ races: this.state.races });
                    this.props.callbackRace(this.state.races);
                },(error) => {
                    console.log(error);
                }
            )
        }else{
            this.state.races[target][name] = e.target.value;
            this.setState({ races: this.state.races });
            this.props.callbackRace(this.state.races);
        }
    }
    
    addRace = function(e){
        e.preventDefault();
        fetch('https://api.goaltiming.be/events/races/new', {
            method: 'GET',
            headers: new Headers({
                'Authorization': this.token
            }), 
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({ races: this.state.races.concat([result]) })
                this.props.callbackRace(this.state.races);
            },(error) => {
                console.log(error);
            }
        )
    }

    removeRace = function(e){
        e.preventDefault();

        this.state.races.splice(e.target.attributes.data_target.nodeValue,1);
        this.setState({ races: this.state.races });
        this.props.callbackRace(this.state.races);
    }

    addRed = function(e){
        e.preventDefault();
        var target = e.target.attributes.data_target.nodeValue;

        this.state.races[target].customFields.push({
            id: this.randomCode(30),
            label: '',
            reduction: 0
        });

        this.setState({ races: this.state.races })
        this.props.callbackRace(this.state.races);
    }

    removeRed = function(e){
        e.preventDefault();
        var target = e.target.attributes.data_target.nodeValue;
        var customField = e.target.attributes.data_custom.nodeValue;

        this.state.races[target].customFields.splice(customField,1);
        this.setState({ races: this.state.races });
        this.props.callbackRace(this.state.races);
    }

    customChange = function(e){
        var target = e.target.attributes.data_target.nodeValue;
        var name = e.target.attributes.data_name.nodeValue;
        var custom = e.target.attributes.data_custom.nodeValue;


        this.state.races[target].customFields[custom][name] = e.target.value;
        this.setState({ races: this.state.races });
        this.props.callbackRace(this.state.races);

        console.log(this.state.races)
    }

    randomCode(length = 10) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    convertToDate = function(timestamp){
        var date = new Date(timestamp*1000);
        var day = ("0" + date.getDate()).slice(-2)
        day = day < 10 ? '0'+day : day;
        day = day.length > 2 ? day.substr(1) : day;
        var month = date.getMonth()+1;
        month = month < 10 ? '0'+month : month;
        month = month.length > 2 ? month.substr(1) : month;
        var year = date.getFullYear();

        return year + "-" + month + "-" + day;
    }

    convertToTime = function(timestamp){
        var date = new Date(timestamp*1000);
        var hour = date.getHours();
        hour = hour < 10 ? '0'+hour : hour;
        var minutes = date.getMinutes();
        minutes = minutes < 10 ? '0'+minutes : minutes;

        return hour + ":" + minutes;
    }

    render() {
        const { error } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div className="card-body">

                    {this.state.races.map((item, i) =>
                        <div key={i}> 
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Nom de la course ({this.state.races[i].id})</label>
                                        <input type="text" className="form-control" value={this.state.races[i].name} name={'race_' + i + '_name'} data_target={i} data_name="name" placeholder="Nom de la course" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['race_' + i + '_name'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                </div>    
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Date de cloture des inscriptions</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="date" className="form-control" name={'race_' + i + '_close'} defaultValue={this.state.races[i].close} data_target={i} data_name="close" ref={this.ref} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="time" className="form-control" name={'race_' + i + '_closeTime'} defaultValue={this.state.races[i].closeTime} data_target={i} data_name="closeTime" ref={this.ref} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Prix</label>
                                        <input type="number" min="-1" step="0.1" className="form-control" value={this.state.races[i].price} name={'race_' + i + '_price'} data_target={i} data_name="price" placeholder="0" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['race_' + i + '_price'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Distance</label>
                                        <input type="number" min="0" className="form-control" value={this.state.races[i].distance} name={'race_' + i + '_distance'} data_target={i} data_name="distance" placeholder="0" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['race_' + i + '_distance'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Nombre de participants maximum</label>
                                        <input type="number" min="0" className="form-control" value={this.state.races[i].maxParticipants} name={'race_' + i + '_maxParticipants'} data_target={i} data_name="maxParticipants" placeholder="0" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['race_' + i + '_maxParticipants'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Challenge ?</label>
                                        <input type="text" className="form-control" value={this.state.races[i].challenge} name={'race_' + i + '_challenge'} data_target={i} data_name="challenge" placeholder="Nom du challenge lié" ref={this.ref} onChange={this.handleChange} />
                                    </div>
                                </div>  
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Prix en cas de pré-enregistrement</label>
                                        <input type="number" min="-1" step="0.1" className="form-control" value={this.state.races[i].preRegistrationPrices} name={'race_' + i + '_preRegistrationPrices'} data_target={i} data_name="preRegistrationPrices" placeholder="0" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['race_' + i + '_preRegistrationPrices'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Départ</label>
                                        <input type="text" className="form-control" value={this.state.races[i].departure} name={'race_' + i + '_departure'} data_target={i} data_name="departure" placeholder="12h00" ref={this.ref({required: true})} onChange={this.handleChange} />
                                        <small className="form-text form-error">{this.errors['race_' + i + '_departure'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Résultats</label>
                                        <input type="file" className="form-control" name={'race_' + i + '_results'} data_target={i} data_name="results" onChange={this.handleChange} />
                                        <input type="hidden" className="form-control" name={'race_' + i + '_results'} ref={this.ref} />
                                        {/* <input type="hidden" className="form-control" name={'race_' + i + '_pdf'} value={this.state.items[i].image} ref={this.ref({required: true})} /> */}
                                        <small className="form-text form-error">{this.errors['race_' + i + '_results'] && <span>Ce champ est requis</span>}</small>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <a style={{float:'right'}} data_target={i} onClick={this.removeRace} href="/">Supprimer la course</a>
                                </div>
                            </div>
                            <div className="card-header">
                                <h5>Champs personnalisés</h5>
                            </div>
                            <br />
                            {item.customFields.map((cf, j) =>
                                <div key={j}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Label</label>
                                                <input type="text" className="form-control" value={item.customFields[j].label} name={'race_' + j + '_customFieldsLabel'} data_target={i} data_custom={j} data_name="label" ref={this.ref({required: true})} onChange={this.customChange} />
                                                <small className="form-text form-error">{this.errors['race_' + j + '_customFieldsLabel'] && <span>Ce champ est requis</span>}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Réduction associée</label>
                                                <input type="number" min="0" step="0.1" value={item.customFields[j].reduction} className="form-control" name={'race_' + j + '_customFieldsRed'} data_target={i} data_custom={j} data_name="reduction" ref={this.ref({required: true})} onChange={this.customChange} />
                                                <small className="form-text form-error">{this.errors['race_' + j + '_customFieldsRed'] && <span>Ce champ est requis</span>}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Valeurs</label>
                                                <textarea type="text" className="form-control" value={item.customFields[j].values} name={'race_' + j + '_customFieldsValues'} data_target={i} data_custom={j} data_name="values" ref={this.ref({required: false})} onChange={this.customChange}></textarea>
                                                <small className="form-text">A remplir pour créer une liste de sélection, une valeur par ligne.</small>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Info bulle</label>
                                                <input type="text" className="form-control" value={item.customFields[j].tooltip} name={'race_' + j + '_customFieldsTooltip'} data_target={i} data_custom={j} data_name="tooltip" placeholder="Info bulle" ref={this.ref} onChange={this.customChange} />
                                            </div>
                                        </div>   
                                    </div>
                                    <a style={{float:'right'}} data_target={i} data_custom={j} onClick={this.removeRed} href="/">Supprimer le champ</a>
                                    <br />
                                </div>
                            )}
                            <a style={{float:'right'}} data_target={i} onClick={this.addRed} href="/">Ajouter un champ</a>
                            <br />
                            <hr />
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" onClick={this.addRace}>Ajouter une course</button>
                            </div>
                        </div>   
                    </div>
                </div>
            );
        }
    }
}

export default Races